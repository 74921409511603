import React from "react";
import Layout from "../components/layout";
import Container from "../components/container";
import { FaBook, FaCamera, FaQuestionCircle, FaMicrophone } from "react-icons/fa";
import { Link } from "gatsby";
import * as styles from "./baza-wiedzy.module.css";
import SEO from "../components/seo";
import FunctionPageLayout from "../components/functionPageLayout";

const BazaWiedzy = () => {
  return (
    <FunctionPageLayout
      title="Questy - baza wiedzy"
      headline="Informacje o Questy i&nbsp;naszych systemach"
    >
      <SEO
        title="Questy - baza wiedzy"
        description="Questy - baza wiedzy ✅ Blog • Poradniki • Vlog • Materiały video"
        keywords={["Firma Questy - blogi, vlogi, poradniki, instrukcje"]}
      />

      <Container>
        <div className={styles.cardContainer}>
          <div>
            <div>
              <FaBook size="45px" />
              <h4>Blog</h4>
              <p>
                Wydarzenia, uroczystości, informacje i&nbsp;ciekawostki dotyczące
                Questy. Sprawdź, co u nas słychać!
              </p>
            </div>
            <Link to="/blog/">Przejdź do bloga</Link>
          </div>
          <div>
            <div>
              <FaCamera size="45px" />
              <h4>Vlog</h4>
              <p>
                Zapraszamy na vloga poruszającego tematykę zarządzania firmą za
                pomocą systemów CRM, B2B i Service Management. Te praktyczne
                materiały pomogą Ci w pełni wykorzystać ich potencjał.
              </p>
            </div>
            <Link to="/vlog/">Przejdź do vloga</Link>
          </div>
        <div>
            <div>
              <FaMicrophone size="45px" />
              <h4>Wywiady</h4>
              <p>
                Wywiady z ekspertami, w których poruszamy tematykę skutecznej obsługi klienta,
                nowoczesnej technologii informatycznej oraz efektywnej sprzedaży. Poznaj punkt
                widzenia wieloletnich praktyków branżowych.
              </p>
            </div>
            <Link to="/wywiady">Przejdź do wywiadów</Link>
          </div>
        </div>
      </Container>
    </FunctionPageLayout>
  );
};

export default BazaWiedzy;

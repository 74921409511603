// extracted by mini-css-extract-plugin
export var featureList = "baza-wiedzy-module--featureList--1MlOb";
export var darkSection = "baza-wiedzy-module--darkSection--1sVMS";
export var enter = "baza-wiedzy-module--enter--2OIae";
export var startNow = "baza-wiedzy-module--startNow--kt0Pm";
export var phoneNum = "baza-wiedzy-module--phoneNum--_0Ryt";
export var buttonWrapper = "baza-wiedzy-module--button-wrapper--2IgMc";
export var container__functions = "baza-wiedzy-module--container__functions--3NwMy";
export var functioncard = "baza-wiedzy-module--functioncard--3x-Ph";
export var termsCard = "baza-wiedzy-module--termsCard--Py18h";
export var textWrapper = "baza-wiedzy-module--textWrapper--2a-Ho";
export var cardContainer = "baza-wiedzy-module--cardContainer--1McJN";